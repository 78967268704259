// comment to trigger build
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'

import {
  Accordion,
  Brandy,
  Columns,
  Column,
  Hero,
  Image,
  LeshenPhoneCTA,
  LinkButton,
  ModalWithState,
  PackageCard,
  SplitContent,
  Stack,
  Link,
  SVGImage,
  useMapi,
  VariableContent,
  ItemBank,
} from '@leshen/gatsby-theme-leshen'

import { Typography, List, ListItem } from '@leshen/ui'
import { graphql } from 'gatsby'

import { cityFaq } from '../data/faq'

import BroadbandNutritionLabels from '../components/BroadbandNutritionLabels'

const CityTemplate = ({ data }) => {
  const { City, State, Meta_Description, Page_Path, Meta_Title, Promo_Code } =
    data.pageInfo.edges[0].node

  const { rotatedNumber } = useMapi()

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: `/availability${Page_Path}`,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  const cityDropdownData = data.cities.edges.map((current) => ({
    label: current.node.City,
    value: `/availability${current.node.Page_Path.toLowerCase()}`,
  }))

  // Sort the array alphabetically based on the 'label' property
  cityDropdownData.sort((a, b) => {
    return a.label.localeCompare(b.label)
  })

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Hero
            textColor="Light"
            image={
              <Image
                data={data.heroDesktopImage.cloudinary[0].gatsbyImageData}
                alt={data.heroDesktopImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
          >
            <Typography variant="h1">Hughesnet® Internet in {City}</Typography>
            <Typography>
              Hughesnet provides High-speed satellite internet services across
              America, powered by JUPITER<sup>™</sup> 3, the world&apos;s
              largest and most advanced commercial communications satellite. See
              what’s available in {City}.
            </Typography>
            <List style={{ marginBottom: '16px' }} type="unordered">
              <ListItem>
                Speeds up to 100 Mbps<sup>**</sup>
              </ListItem>
              <ListItem>
                Unlimited data<sup>1</sup>
              </ListItem>
              <ListItem>Price-locked plans</ListItem>
              <ListItem>Available in rural areas</ListItem>
            </List>
            <LeshenPhoneCTA variant="hero" color="primary" leftIcon={false}>
              Call now
            </LeshenPhoneCTA>
          </Hero>

          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <Typography variant="h2">
                  Hughesnet is Available in {City}, {State}
                </Typography>
                <Typography variant="body">
                  With Hughesnet’s widespread availability, {City} residents
                  have access to all the benefits of Hughesnet High-speed
                  internet.
                </Typography>
              </>
            }
          >
            <Stack spacing="xxl">
              <Columns leftAligned={false} mobileCarousel>
                <Column
                  image={
                    <Image
                      data={data.columnImageOne.cloudinary[0].gatsbyImageData}
                      alt={data.columnImageOne.cloudinary[0].context.custom.alt}
                    />
                  }
                >
                  <br />
                  <Typography variant="h4">Speed</Typography>
                  <Typography>
                    The new Hughesnet is faster than ever, with speeds up to 100
                    Mbps<sup>**</sup> in {City} and lower latency for those who
                    qualify for Fusion.
                  </Typography>
                </Column>
                <Column
                  image={
                    <Image
                      data={data.columnImageTwo.cloudinary[0].gatsbyImageData}
                      alt={data.columnImageOne.cloudinary[0].context.custom.alt}
                    />
                  }
                >
                  <br />
                  <Typography variant="h4">Unlimited Data</Typography>
                  <Typography>
                    All Hughesnet plans provide unlimited data<sup>1</sup>, so
                    you can surf and stream whenever you want, for as long as
                    you want.
                  </Typography>
                </Column>
                <Column
                  image={
                    <Image
                      data={data.columnImageThree.cloudinary[0].gatsbyImageData}
                      alt={
                        data.columnImageThree.cloudinary[0].context.custom.alt
                      }
                    />
                  }
                >
                  <br />
                  <Typography variant="h4">Price Lock Guarantee</Typography>
                  <Typography>
                    With Hughesnet, your monthly service fee will not change for
                    the first two years, so you can enjoy your internet without
                    stress.
                  </Typography>
                </Column>
                <Column
                  image={
                    <Image
                      data={data.columnImageFour.cloudinary[0].gatsbyImageData}
                      alt={
                        data.columnImageThree.cloudinary[0].context.custom.alt
                      }
                    />
                  }
                >
                  <br />
                  <Typography variant="h4">
                    Professional Installation
                  </Typography>
                  <Typography>
                    Hughesnet provides free professional installation, so you
                    don’t need to worry about setting up a satellite—we got you
                    covered.
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
          <SplitContent
            image={
              <Image
                data={data.movingDesktop.cloudinary[0].gatsbyImageData}
                alt="Woman sitting on the floor with moving boxes around her"
              />
            }
            mobileImage={
              <Image
                data={data.movingMobile.cloudinary[0].gatsbyImageData}
                alt="Woman sitting on the floor with moving boxes around her"
              />
            }
            mainContent={
              <>
                <Typography variant="h2">Moving with Hughesnet</Typography>
                <Typography variant="h4">
                  Stay connected even when you change addresses.
                </Typography>
                <Typography variant="body">
                  Hughesnet makes moving your internet a breeze. With three
                  simple steps, your internet will be up and running as soon as
                  you’ve settled into your new home.
                </Typography>
                <List>
                  <ListItem>
                    <Typography>
                      <strong>Call customer service:</strong> Talk to a customer
                      support representative to find the best Hughesnet offers
                      at your new location. They’ll set you up with the best
                      plan and schedule your installation.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      <strong>Move your equipment:</strong> Simply bring your
                      modem and power supply to your new home—a skilled
                      technician will take care of the rest.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      <strong>Connect:</strong> Upgrade to a new service or keep
                      your existing plan. We’ll get you set up with a new
                      24-month term that will start on your next billing cycle.
                    </Typography>
                  </ListItem>
                </List>
                <br />
                <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
              </>
            }
          />
          <SplitContent
            image={
              <Image
                data={data.installationGuyDesktop.cloudinary[0].gatsbyImageData}
                alt="An installer standing in a doorway waiting to go inside"
              />
            }
            mobileImage={
              <Image
                data={data.installationGuyMobile.cloudinary[0].gatsbyImageData}
                alt="An installer standing in a doorway waiting to go inside"
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Hughesnet Installation Made Easy
                </Typography>
                <Typography variant="body">
                  Once you’ve signed up for service, a customer support
                  representative will schedule a date and time that works for
                  you to have a professional technician come to your home.
                </Typography>
                <List>
                  <ListItem>
                    <Typography>
                      The technician will install a satellite dish and mounting
                      rod on an elevated surface.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      They will then run a cable from the outside antenna to the
                      modem inside your home.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Finally, the technician will make sure the system is
                      grounded and sealed with water-resistant fittings, and
                      your internet will be ready to go!
                    </Typography>
                  </ListItem>
                </List>
                <br />
                <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <Typography variant="h2">Hughesnet Add-Ons</Typography>
                <Typography variant="body">
                  Hughesnet offers several additional services to enhance your
                  home internet connection.
                </Typography>
                <LinkButton outlined to="/additional-services">
                  Learn More
                </LinkButton>
                <br />
              </>
            }
          >
            <Stack spacing="xxl">
              <Columns leftAligned={false} mobileCarousel>
                <Column
                  backgroundColor="light"
                  image={
                    <Image
                      className="card-icon"
                      data={data.shield.cloudinary[0].gatsbyImageData}
                      alt={data.shield.cloudinary[0].context.custom.alt}
                    />
                  }
                >
                  <Typography variant="h4">Norton™ 360 Deluxe</Typography>
                  <Typography>
                    This security package protects up to 5 devices with a full
                    suite of services.
                  </Typography>
                </Column>
                <Column
                  backgroundColor="light"
                  image={
                    <Image
                      className="card-icon"
                      data={data.tools.cloudinary[0].gatsbyImageData}
                      alt={data.tools.cloudinary[0].context.custom.alt}
                    />
                  }
                >
                  <br />
                  <Typography variant="h4">Hughesnet Express Repair</Typography>
                  <Typography>
                    Get an expedited technician appointment at a reduced price.
                  </Typography>
                </Column>
                <Column
                  backgroundColor="light"
                  image={
                    <Image
                      className="card-icon"
                      data={data.landline.cloudinary[0].gatsbyImageData}
                      alt={data.landline.cloudinary[0].context.custom.alt}
                    />
                  }
                >
                  <br />
                  <Typography variant="h4">Hughesnet Voice</Typography>
                  <Typography>
                    This VoIP (Voice-over-IP) home phone service never
                    interferes with your internet or uses your Priority Data.
                  </Typography>
                </Column>
                <Column
                  backgroundColor="light"
                  image={
                    <Image
                      className="card-icon"
                      data={data.wifi.cloudinary[0].gatsbyImageData}
                      alt={data.wifi.cloudinary[0].context.custom.alt}
                    />
                  }
                >
                  <br />
                  <Typography variant="h4">Whole Home Wi-Fi</Typography>
                  <Typography>
                    With the new Hughesnet modem, you get faster speeds, more
                    data, and mesh Wi-Fi extenders for strong service throughout
                    your home.
                  </Typography>
                </Column>
                <Column
                  backgroundColor="light"
                  image={
                    <Image
                      className="card-icon"
                      data={data.phone.cloudinary[0].gatsbyImageData}
                      alt={data.phone.cloudinary[0].context.custom.alt}
                    />
                  }
                >
                  <br />
                  <Typography variant="h4">Hughesnet Mobile App</Typography>
                  <Typography>
                    Get easy access to customer service and helpful tools to
                    manage your account.
                  </Typography>
                </Column>
                <Column
                  backgroundColor="light"
                  image={
                    <Image
                      className="card-icon"
                      data={data.gbTokens.cloudinary[0].gatsbyImageData}
                      alt={data.gbTokens.cloudinary[0].context.custom.alt}
                    />
                  }
                >
                  <br />
                  <Typography variant="h4">Priority Data Tokens</Typography>
                  <Typography>
                    Stream, game, and video call at full speeds without
                    reverting to Standard Data speeds when you purchase Priority
                    Data Tokens.
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
          <VariableContent
            mainContent={
              <Typography variant="h2">Frequently asked questions</Typography>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl" alignMainContent="center">
              <Accordion items={cityFaq({ City, State, rotatedNumber })} />
            </Stack>
          </VariableContent>

          <BroadbandNutritionLabels />

          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Hughesnet Internet Availability in {State} Cities
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            breadcrumbProps={null}
            customLink={null}
            style={{ paddingTop: '0px' }}
          >
            <ItemBank className="city-link-bank">
              {cityDropdownData?.map((current, i) => (
                <Link to={current.value} key={i}>
                  {current.label}
                </Link>
              ))}
            </ItemBank>
          </VariableContent>
        </>
      }
    />
  )
}

CityTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default CityTemplate

export const query = graphql`
  query CityTemplateQuery($pagePath: String!, $stateAbbreviation: String!) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerHughesnetAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          City
          Page_Path
          Promo_Code
          Meta_Title
          Meta_Description
        }
      }
    }
    cities: allDatasetManagerHughesnetAoa(
      filter: {
        State_Abbreviation: { eq: $stateAbbreviation }
        Area_Type: { eq: "city" }
      }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    heroDesktopImage: contentfulMedia(
      contentful_id: { eq: "2kMCbhK4oZPs8Pk2FbqODK" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroMobileImage: contentfulMedia(
      contentful_id: { eq: "1Rg9eOhu6aZqLpduFAetJY" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 500)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    columnImageOne: contentfulMedia(
      contentful_id: { eq: "3nuW0Zv0IKNxgL7WkOXxA9" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 500)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    columnImageTwo: contentfulMedia(
      contentful_id: { eq: "5qqGqfdYgls27KqeK1VB4J" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 500)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    columnImageThree: contentfulMedia(
      contentful_id: { eq: "7veB6lAz4Qg4bb8eHeggNc" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 500)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    columnImageFour: contentfulMedia(
      contentful_id: { eq: "YxntROHHLOvjmXOS5r5R4" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 500)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    movingDesktop: contentfulMedia(
      contentful_id: { eq: "4blp1gQrnNumTKireyNJ7g" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 568)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    movingMobile: contentfulMedia(
      contentful_id: { eq: "tRlc62WbeIPGQhQRlWEbm" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 500)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    installationGuyDesktop: contentfulMedia(
      contentful_id: { eq: "51rkGwjYyEkULaHIdg6QKC" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 500)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    installationGuyMobile: contentfulMedia(
      contentful_id: { eq: "3e1Obdl0tW780Xw1BC98lC" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 500)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    shield: contentfulMedia(contentful_id: { eq: "6sjYNOyEEN8S9EpOcys51c" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    tools: contentfulMedia(contentful_id: { eq: "38WemMz9Kz70yztX7DIGSI" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    landline: contentfulMedia(contentful_id: { eq: "3W13oC4fJ9bIjYELYcjWTk" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    wifi: contentfulMedia(contentful_id: { eq: "507iV6rXugBkaazgVUyGtl" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    phone: contentfulMedia(contentful_id: { eq: "1QeF6OnvzMFIGZwCLZsJin" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    gbTokens: contentfulMedia(contentful_id: { eq: "7fx3xJ5HbujdOjRiTLxba5" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    disclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-termrequired" }
    ) {
      id
      text
      symbol
    }
    disclaimerTwo: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-footer1" }
    ) {
      id
      text
      symbol
    }
    disclaimerThree: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-professionalinstallation" }
    ) {
      id
      text
      symbol
    }
    disclaimerFour: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-footer2" }
    ) {
      id
      text
      symbol
    }
    disclaimerFive: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-norton-copyright" }
    ) {
      id
      text
      symbol
    }
    disclaimerSix: brandyDisclaimer(brandy_id: { eq: "hn-copyright" }) {
      id
      text
      symbol
    }
    package1: brandyPackage(brandy_id: { eq: "hn-sigil-elite" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    packageModal1: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-ppcdynamic-modal" }
    ) {
      id
      text
      symbol
    }
    packageModal2: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-norton-copyright" }
    ) {
      id
      text
      symbol
    }
    packageModal3: brandyDisclaimer(brandy_id: { eq: "hn-copyright" }) {
      id
      text
      symbol
    }
    package2: brandyPackage(brandy_id: { eq: "hn-sigil-select" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    packageDisclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-ppcdynamic-modal" }
    ) {
      id
      text
      symbol
    }
    packageDisclaimerTwo: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-norton-copyright" }
    ) {
      id
      text
      symbol
    }
    packageDisclaimerThree: brandyDisclaimer(
      brandy_id: { eq: "hn-copyright" }
    ) {
      id
      text
      symbol
    }
    allContentfulHeader(filter: { identifier: { eq: "SEO - English" } }) {
      edges {
        node {
          id
          identifier
          __typename
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(filter: { identifier: { eq: "SEO - English" } }) {
      edges {
        node {
          id
          identifier
          __typename
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
